.main-goods {
  padding-top: 0.1rem;
  width: 100%;
  background-color: #FFFFFF;
}

.main-goods .item {
  width: 3.55rem;
  height: 1.05rem;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  border-radius: 0.08rem;
  overflow: hidden;
  position: relative;
  padding: 0.1rem;
  box-sizing: border-box;
}

.main-goods .item .item-images {
  width: 0.9rem;
  height: 0.9rem;
}

.main-goods .item .item-content {
  width: 2.5rem;
  height: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-goods .item .item-content .item-title {
  width: 100%;
  padding: 0.05rem 0.1rem;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.main-goods .item .item-content .item-title .name {
  font-size: 0.16rem;
}

.main-goods .item .item-content .spec {
  padding: 0 0.1rem;
  box-sizing: border-box;
}

.main-goods .item .item-content .spec span {
  padding: 0.02rem 0.08rem;
  box-sizing: border-box;
  background-color: #F6F7F8;
  color: #9B9B9B;
  font-size: 0.16rem;
  border-radius: 0.04rem;
}

.main-goods .item .item-content .item-money {
  padding: 0.03rem 0.1rem;
  box-sizing: border-box;
  display: flex;
}

.main-goods .item .item-content .item-money .money {
  font-size: 0.16rem;
  color: #FF6934;
}

.main-goods .item .item-content .item-money .qty {
  color: #cccccc;
  font-size: 0.12rem;
  margin-top: 0.04rem;
  margin-left: 0.01rem;
}

.main-goods .total-money {
  display: flex;
  justify-content: flex-end;
  padding: 0.1rem;
}

.main-goods .total-money .qty {
  font-size: 0.15rem;
  color: #B2B2B2;
  margin-right: 0.1rem;
}

.main-goods .total-money .money {
  font-size: 0.15rem;
  color: #FA8F6A;
}

.order_img {
  width: 1rem;
  display: block;
  border-radius: 0.05rem;
  height: 0.8rem;
}

.cell_box {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.pay_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  height: 0.5rem;
  z-index: 100;
  padding-left: 0.2rem;
}

.btn_pay {
  background: #ed424a;
  color: #fff;
  width: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}